import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";

import Wedge from './Wedge';
import Device from './Device';

import '../styles/landing.scss';
import AppStoreButton from './AppStoreButton';

import fullLogo from '../images/fullLogo.svg';
import partialLogo from '../images/partialLogo.svg';
import iconIG from '../images/icon-IG.svg';
import iconTwitter from '../images/icon-Twitter.svg';
import iconEmail from '../images/icon-Email.svg';

class Landing extends React.Component {
  componentDidMount() {
    document.body.setAttribute('data-section', 'landing');
    this.props.setNavBgClass('colorful-section');
    this.props.a11yNavHandler('Navigated to Home');
  }

  render() {
    return (
      <div className="page-wrapper">
        <section className="colorful-section landing-section-1-offset">
          <div className="inner grid grid-halves landing-section-1 landing-section-1-correction">
            <div className="landing-section-1-content">
              <h1>
                The podcast app that raises money for good causes.
              </h1>
              <p>
                By simply using Humbly, you're helping non-profits and charities raise money.
                {/*So far we've raised $1,230,041! <span role="img" aria-label="celebration emoji">🎉</span>*/}
              </p>
              <div className="landing-cta">
                <AppStoreButton />
                <div className="landing-cta-secondary">
                  <NavLink className="mod-witharrow" to="/advertise">
                    Advertise with us
                    <span className="little-arrow"></span>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="device-wrapper">
              <Device source="landing" />
            </div>
          </div>
        </section>
        <Wedge position="bottom" bgClass="colorful-section" />

        <section>
          <div className="landing-section-2" id="how-it-works">
            <div className="inner grid">
              <h2>How it works</h2>
              <div className="timeline">
                <div className="timeline-gradients"></div>
                <div className="timeline-points grid mod-grid-spaced">
                  <div>
                    <div className="flex mod-flex-column">
                      <p>First, pick the causes that matter to you the most</p>
                      <p className="oblique">Choose from groups of charities and non-profits to support - you can change these at any time</p>
                    </div>
                  </div>
                  <div>
                    <div className="flex mod-flex-column">
                      <p>Then find and listen to any of your favorite podcasts</p>
                      <p className="oblique">Every podcast that's currently available in Apple podcasts, or any other app is available in Humbly</p>
                    </div>
                  </div>
                  <div>
                    <div className="flex mod-flex-column">
                      <p>When you start a podcast episode, you'll hear a short ad or two</p>
                      <p className="oblique">We will strive to make these ads enjoyable for you - not loud and annoying</p>
                    </div>
                  </div>
                  <div>
                    <div className="flex mod-flex-column">
                      <p>Lastly, because you heard ads, money goes towards the causes you've picked</p>
                      <p className="oblique">Someone paid for you to hear that ad, that's where the money comes from</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="landing-section-3" id="about">
            <div className="slant">
              <AppStoreButton
                classes="lower-cta"
              />
              <Wedge position="top" bgClass="colorful-section" />
            </div>
            <div className="landing-section-3-content colorful-section">
              <div className="inner grid">
                <div>
                  <h2>About</h2>
                  <p>
                    Podcasts add value to our lives. They are exist to help us educate, reflect and wonder on endless topics. We at Humbly believe that we can help communities around the world by harnessing the power of podcasts.
                  </p>
                  <p>
                    Humbly is proudly built in California and Armenia.
                  </p>
                  <div className="about-social">
                    <div>
                      <a href="https://www.instagram.com/humblyFM" className="about-social-button" aria-label="Humbly Instagram @humblyFM">
                        <img src={iconIG} alt="Instagram icon" />
                        <span>@humblyFM</span>
                      </a>

                      <a href="https://www.twitter.com/humblyFM" className="about-social-button" aria-label="Humbly Twitter @humblyFM">
                        <img src={iconTwitter} alt="Twitter icon" />
                        <span>@humblyFM</span>
                      </a>

                      <a href="mailto:hello@humbly.fm" className="about-social-button" aria-label="contact Humbly hello@humbly.fm">
                        <img src={iconEmail} alt="Email icon" />
                        <span>hello@humbly.fm</span>
                      </a>
                    </div>
                    <img className="hide-for-mediumlarge" src={partialLogo} alt="Partial logo" />
                  </div>
                </div>
                <div>
                  <img className="hide-for-small" src={fullLogo} alt="Full logo" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
};

Landing.propTypes = {
  setNavBgClass: PropTypes.func
}

export default Landing;