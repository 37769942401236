import React from 'react';

import '../styles/legal.scss';

class TermsAndConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      version: null,
      contents: null
    }
    this.termsData = props.storage.ref('legal/terms_and_conditions_app-latest.json');
  }

  componentDidMount() {
    const that = this;
    this.termsData.getDownloadURL().then(function (url) {
      fetch(url).then(response => response.json()).then(data => {
        that.setState({
          date: data.date,
          version: data.version,
          contents: data.contents
        })
      });
    }).catch(function (error) {
      console.log(error);
    })

    document.title = 'Humbly | Terms and Conditions';
    document.body.setAttribute('data-section', 'terms-and-conditions');
    this.props.a11yNavHandler('Navigated to Terms and Conditions');
  }

  render() {
    return (
      <div className="inner">
        <div dangerouslySetInnerHTML={{ __html: this.state.contents }}></div>
        <br />
        <div dangerouslySetInnerHTML={{ __html: this.state.date }}></div>
        <div dangerouslySetInnerHTML={{ __html: this.state.version }}></div>
      </div>
    )
  }
};

export default TermsAndConditions;