import React from 'react';

import '../styles/legal.scss';

class Privacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      version: null,
      contents: null
    }
    this.privacyData = props.storage.ref('legal/privacy_policy_app-latest.json');
  }

  componentDidMount() {
    const that = this;
    this.privacyData.getDownloadURL().then(function (url) {
      fetch(url).then(response => response.json()).then(data => {
        that.setState({
          date: data.date,
          version: data.version,
          contents: data.contents
        })
      });
    }).catch(function (error) {
      console.log(error);
    })

    document.title = 'Humbly | Privacy Policy';
    document.body.setAttribute('data-section', 'privacy-policy');
    this.props.a11yNavHandler('Navigated to Privacy');
  }

  render() {
    return (
      <div className="inner">
        <div dangerouslySetInnerHTML={{ __html: this.state.contents }}></div>
        <br />
        <div dangerouslySetInnerHTML={{ __html: this.state.date }}></div>
        <div dangerouslySetInnerHTML={{ __html: this.state.version }}></div>
      </div>
    )
  }
};

export default Privacy;