import React from 'react';
import { PropTypes } from 'prop-types';

import LandingPoster from '../video/landing-poster.jpg';
import LandingDemoMP4 from '../video/landing-demo.mp4';
import LandingDemoOGG from '../video/landing-demo.ogg';

import AdvertisePoster from '../video/ad-poster.jpg';
import AdvertiseDemoMP4 from '../video/ad-demo.mp4';
import AdvertiseDemoOGG from '../video/ad-demo.ogg';

class Device extends React.Component {
  constructor() {
    super();
    this.deviceRef = React.createRef();
    this.state = {
      poster: null,
      sourceMP4: null,
      sourceOGG: null
    }
  }

  setSize() {
    if (this.deviceRef.current) {
      const device = this.deviceRef.current;
      const deviceWidth = device.offsetWidth;
      const ratios = {
        height: 2.1,
        corner: .137
      }
      device.style.height = (deviceWidth * ratios.height) + 'px';
      device.style.borderRadius = (deviceWidth * ratios.corner) + 'px';
    }
  }

  componentDidMount() {
    const windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      if (windowWidth > 600) {
        return;
      } else {
        this.setSize();
      }
    })
    if (windowWidth <= 600) this.setSize();

    if (this.props.source === 'landing') {
      this.setState({
        poster: LandingPoster,
        sourceMP4: LandingDemoMP4,
        sourceOGG: LandingDemoOGG
      })
    }
    if (this.props.source === 'advertise') {
      this.setState({
        poster: AdvertisePoster,
        sourceMP4: AdvertiseDemoMP4,
        sourceOGG: AdvertiseDemoOGG
      })
    }
  }

  render() {
    const { sourceMP4, sourceOGG, poster } = this.state;
    return (
      <div className="device" ref={this.deviceRef}>
        <div className="device-contents">
          <video id="video" autoPlay  muted={true} poster={poster}>
            <source src={sourceMP4} type="video/mp4" />
            <source src={sourceOGG} type="video/ogg" />
          </video>
        </div>
      </div>
    )
  }
}

Device.propTypes = {
  source: PropTypes.string.isRequired
}

export default Device;