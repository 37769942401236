import React from 'react';

class Wedge extends React.Component {
  render() {
    const { position, bgClass } = this.props;
    function whichWedge() {
      if (position === 'top') {
        return (
          <svg className="wedge mod-wedge-top" viewBox="0 0 1200 180" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path className={bgClass} d="m0 180.742404 1200-180.742404v180.742404h-871z" />
            </g>
          </svg>
        )
      }
      if (position === 'bottom') {
        return (
          <svg className="wedge mod-wedge-bottom" viewBox="0 0 1200 180" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path className={bgClass} d="m0 180.742404 1200-180.742404v180.742404h-871z" />
            </g>
          </svg>
        )
      }
    }
    return (
      <>
        {whichWedge()}
      </>
    )
  }
}

export default Wedge;