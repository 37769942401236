import React from 'react';

export default function CostBreakdown() {
  return (
    <div className="cost-breakdown">
      <div>
        <div className="cost-breakdown-amount">
          2¢ <span>AND UP</span>
        </div>
        <div className="cost-breakdown-text">
          <h4>Cost per listen</h4>
          <span>Add
              Add 1¢ for each additional targeting parameter<br />100% of this goes to causes!
            </span>
        </div>
      </div>

      <div className="cost-breakdown-splitter">
        <div></div>
        <div>PLUS</div>
        <div></div>
      </div>

      <div>
        <div className="cost-breakdown-amount">
          10¢ <span>AND UP</span>
        </div>
        <div className="cost-breakdown-text">
          <h4>Cost per click</h4>
          <span>
            Add 5¢ for each additional targeting parameter
            </span>
        </div>
      </div>
    </div>
  )
}