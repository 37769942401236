import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import CostBreakDown from './CostBreakdown';
import Wedge from './Wedge';

import '../styles/ad-details.scss';

class AdDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      spend: null,
      listens: null,
      taps: null,
    }
    this.calculatePricing = this.calculatePricing.bind(this);
    this.calculateDollars = React.createRef();
    this.calculateParameters = React.createRef();
  }

  calculatePricing() {
    const spendValue = this.calculateDollars.current.value ? parseInt(this.calculateDollars.current.value, 10) : 0;
    const paramsNumber = this.calculateParameters.current.value ? parseInt(this.calculateParameters.current.value, 10) : 0;

    const tapsPercent = .05;
    const costPerParameterListen = .01;
    const costPerParameterTap = .05;

    const outcomes = {
      taps: 0,
      listens: 0
    }

    const costPerListen = (.02 + (paramsNumber * costPerParameterListen)).toFixed(2);
    const costPerTap = (.10 + (paramsNumber * costPerParameterTap)).toFixed(2);

    function calc(spend, params) {
      outcomes.taps = Math.floor((spend * tapsPercent) / costPerTap);
      outcomes.listens = Math.floor((spend * (1 - tapsPercent)) / costPerListen);
    }

    calc(spendValue, paramsNumber);

    this.setState({
      spend: spendValue,
      taps: outcomes.taps,
      listens: outcomes.listens
    })
  }

  componentDidMount() {
    this.calculatePricing();
    document.title = 'Humbly | Ad Details';
    document.body.setAttribute('data-section', 'ad-details');
    this.props.setNavBgClass('colorful-section');
    this.props.a11yNavHandler('Navigated to Ad Details');
  }

  render() {
    const {
      spend,
      listens,
      taps,
    } = this.state;

    return (
      <div className="page-wrapper">
        <section className="colorful-section">
          <div className="inner grid grid-full ad-details-section-1">
            <div className="less-wide">
              <h1>Humbly ads</h1>
              <p>
                If you're here, you're probably considering advertising in the podcast space – Good call! It's an effective and awesome way to get your message in front of engaged listeners. We think Humbly will prove to be amongst the best podcast advertising options around.
              </p>
              <p>
                We have a simple, and humane approach to serving ads. Simple in a sense of pricing and ad creation. It's humane because of the high quality of the ads, the fact that they do not interrupt the listener - in fact they're predictable. Here's the basic flow...
              </p>

              <div className="basic-flow">
                <div className="basic-flow-item">
                  <div className="basic-flow-line"></div>
                  <div className="basic-flow-circle"><span role="img" aria-label="play arrow icon">▶️</span></div>
                  <div>
                    <h3 className="basic-flow-headline">Listener taps "Play" on a podcast</h3>
                    <p className="basic-flow-subline">It can be either a new episode, or resuming one from before</p>
                  </div>
                </div>
                <div className="basic-flow-item">
                  <div className="basic-flow-line"></div>
                  <div className="basic-flow-circle"><span role="img" aria-label="ear icon">👂</span></div>
                  <div>
                    <h3 className="basic-flow-headline">They'll immediately hear your Humbly ad</h3>
                    <p className="basic-flow-subline">If the ad gets skipped, it will not count as a listen</p>
                  </div>
                </div>
                <div className="basic-flow-item">
                  <div className="basic-flow-line"></div>
                  <div className="basic-flow-circle"><span role="img" aria-label="replay icon">🔁</span></div>
                  <div>
                    <h3 className="basic-flow-headline">Then the podcast starts or resumes</h3>
                    <p className="basic-flow-subline">Your message and brand has now crossed the listener</p>
                  </div>
                </div>
                <div className="basic-flow-item">
                  <div className="basic-flow-line"></div>
                  <div className="basic-flow-circle"><span role="img" aria-label="money icon">💵</span></div>
                  <div>
                    <h3 className="basic-flow-headline">Listener's causes receive money</h3>
                    <p className="basic-flow-subline">There are positive feelings around hearing an ad</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="slant">
          <span className="section-emoji" role="img" aria-label="New sparkle icon">✨</span>
          <Wedge position="bottom" bgClass="colorful-section" />
        </div>

        <section>
          <div className="inner grid grid-full ad-details-section-2">
            <div className="less-wide">
              <h2>Ad creation</h2>
              <p>
                Use either your existing ad audio (15 - 30 seconds long), or let us create a high quality ad for you at not cost with the help of our professional voice actors and audio editors.
              </p>
              <p>
                We'll also need an image to show listeners, and URL for the action button. You can also choose which users should hear your ad - more on that below.
              </p>
              <p>
                The creation all happens over email and/or phone. We feel like a personal touch is helpful here, since you are spending hard-earned money with us.
              </p>
              <p>
                Before your ad goes live, you'll be able to review it - in case you want any last minute tweaks.
              </p>
            </div>
          </div>
        </section>

        <section>
          <div className="slant">
            <span className="section-emoji" role="img" aria-label="Bullseye icon">🎯</span>
            <Wedge position="top" bgClass="colorful-section" />
          </div>
          <div className="colorful-section">
            <div className="inner grid grid-full ad-details-section-3">
              <div className="less-wide">
                <h2>Listener targeting</h2>
                <p>
                  You can get specific with who gets to hear your ad. Currently, Humbly offers the following targeting preferences:
                </p>
                <div className="pills-section">
                  <span>Age</span>
                  <span>Gender</span>
                  <span>Location</span>
                  <span>Zip code</span>
                  <span>Podcast subscriptions</span>
                  <span>Podcast genres</span>
                </div>
                <p>
                  This will get your message heard by the right listeners, in addition to raising more money for the causes they've selected.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="slant">
          <span className="section-emoji" role="img" aria-label="Money with wings icon">💸</span>
          <Wedge position="bottom" bgClass="colorful-section" />
        </div>

        <section>
          <div className="inner grid grid-full ad-details-section-4">
            <div className="less-wide">
              <h2>Pricing</h2>
              <p>
                We've taken a slightly different, but simple approach to pricing. We include both listens and clicks (also known as CPC) together. You set a budget, select your targeting parameters, and let Humbly ads get to work for you.
              </p>
              <CostBreakDown />

              <p>Pricing calculator</p>
              <div className="pricing-calculator">
                <div className="pricing-calculator-inputs">
                  <div>
                    <label htmlFor="calc-amoumt">Budget ($)</label>
                    <input
                      id="calc-amount"
                      ref={this.calculateDollars}
                      placeholder="$ USD"
                      defaultValue={100}
                      onChange={() => { this.calculatePricing() }}
                    />
                  </div>

                  <div>
                    <label htmlFor="calc-target">Targeting parameters (0-6)</label>
                    <input
                      id="calc-target"
                      type="number"
                      min="0"
                      max="6"
                      defaultValue={0}
                      ref={this.calculateParameters}
                      onChange={() => { this.calculatePricing() }}
                    />
                  </div>
                </div>
                <div className="pricing-calculator-results">
                  <table>
                    <thead>
                      <tr>
                        <th>Examples</th>
                        <th>Outcome</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Listens</td>
                        <td>{listens}</td>
                      </tr>
                      <tr>
                        <td>Taps</td>
                        <td>{taps}</td>
                      </tr>
                      <tr>
                        <td>Cost</td>
                        <td>${spend}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="pricing-calculator-results-notice">
                    This approximate example is based on 5% of users tapping/clicking the action button
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="slant">
            <span className="section-emoji" role="img" aria-label="Megaphone icon">📣</span>
            <Wedge position="top" bgClass="colorful-section" />
          </div>
          <div className="colorful-section">
            <div className="inner grid grid-full ad-details-section-5">
              <div className="less-wide">
                <h2>Your new audience</h2>
                <p>
                  One of the reasons people use Humbly is because it raises money for causes, simply by using it. Since the listeners themselves are able to choose which charities or nonprofits get the benefits, they feel more connected.
                </p>
                <p>
                  Additionally, research shows that podcast listeners tend to be more engaged, educated, and willing to accept brands that are presented to them through the podcast space. Humbly listeners specifically, are paying attention.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="ad-details-section-6">
            <div className="inner">
              <div className="bottom-cta">
                <p>
                  Ready to advertise or have questions?
                </p>
                <Link to="/contact">
                  <button className="button button-regular">
                    Get in touch
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
};

AdDetails.propTypes = {
  setNavBgClass: PropTypes.func
}

export default AdDetails;