import React from 'react';
import '../styles/nav.scss';

import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import AppStoreButton from './AppStoreButton';
import circleHlogo from '../images/circleHlogo.svg';
import fullLogo from '../images/fullLogo.svg';

class Nav extends React.Component {
  render() {
    const {
      menuHandler,
      isMenuOpen,
      isButtonStuck,
      navBgClass
    } = this.props;
    return (
      <div className={`nav-wrapper ${navBgClass}`}>
        <div className={`menu-mobile ${isMenuOpen ? 'mod-menu-open' : ''}`}>
          <div className={`menu-mobile-overlay ${navBgClass}`}>
            <img className="menu-mobile-logo" src={fullLogo} alt="Full humbly logo" />
            <div className="menu-mobile-links">
              <Link to="/" onClick={menuHandler} className="menu-mobile-link">Home</Link>
              <Link smooth to="/#how-it-works" onClick={menuHandler} className="menu-mobile-link">How it works</Link>
              <Link smooth to="/#about" onClick={menuHandler} className="menu-mobile-link">About</Link>
              <Link to="/advertise" onClick={menuHandler} className="menu-mobile-link">Advertise with us</Link>
            </div>
            <AppStoreButton
              classes="menu-mobile-app-store"
            />
          </div>
        </div>
        <header className="header">
          <NavLink to="/">
            <img className="header-logo" src={circleHlogo} alt="Humbly home" />
          </NavLink>
          <nav className="header-nav">
            <ul className="header-links">
              <li className="header-link"><Link smooth to="/#how-it-works">How it works</Link></li>
              <li className="header-link"><Link smooth to="/#about">About</Link></li>
            </ul>
          </nav>
          <NavLink className="header-right-link mod-witharrow" to="/advertise">
            Advertise with us
            <span className="little-arrow"></span>
          </NavLink>
          <button className={`button button-menu ${isButtonStuck ? 'mod-menu-close' : 'mod-menu-open'} ${isMenuOpen ? 'mod-menu-closed' : ''}`} onClick={() => menuHandler()}>
            <div className="button-menu-line"></div>
            <div className="button-menu-line"></div>
            <div className="button-menu-line"></div>
            <div className="button-menu-line"></div>
          </button>
        </header>
      </div>
    )
  }
}

export default Nav;