import React from 'react';

import '../styles/contact.scss';

class Contact extends React.Component {
  constructor(){
    super();
    this.state = {
      isSending: false,
      justSent: false
    }
    this.handleSend = this.handleSend.bind(this);
  }

  componentDidMount() {
    this.props.a11yNavHandler('Navigated to Contact');
  }

  handleSend(e) {
    e.preventDefault();
    this.setState({
      isSending: true
    })

    const url = 'https://us-central1-humbly-app-7410e.cloudfunctions.net/contactFormDev'
    const target = e.target;
    const body = {
      name: target.name.value,
      company: target.company.value,
      email: target.email.value,
      phone: target.phone.value,
      bodyHTML: target.body.value
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(body)
    }).then(res => {
      this.setState({
        isSending: false,
        justSent: true
      }, () => {
        setTimeout(() => {
          this.setState({
            justSent: false
          })
        }, 5000);
      })
    }).catch(err => {
      this.setState({
        isSending: false
      })
      console.error(err)
    });
  }

  determineButtonContents() {
    if (this.state.isSending) {
      return <div className="loading-thin"></div>;
    } else if (this.state.justSent) {
      return <span>Sent! <span role="img" aria-label="Thumbs up emoji">👍</span></span>;
    } else {
      return "Send";
    }
  }

  render() {
    return (
      <div className="inner contact">
        <div className="contact-heading">
          <h2>Let's work together <span role="img" aria-label="handshake emoji">🤝</span></h2>
          <p>Fill out the info below to get the ball rolling on advertising with Humbly. We can't wait to help you get your message out there!</p>
        </div>
        <form onSubmit={ this.handleSend }>
          <div className="contact-wrapper grid">
            <div className="contact-item">
              <label htmlFor="name">Name</label>
              <input id="name" name="name" required />
            </div>

            <div className="contact-item">
              <label htmlFor="company">Company, org etc (optional)</label>
              <input id="company" name="company" />
            </div>

            <div className="contact-item">
              <label htmlFor="email">Email address</label>
              <input id="email" type="email" name="email" required />
            </div>

            <div className="contact-item">
              <label htmlFor="phone">Phone number (optional)</label>
              <input id="phone" type="tel" name="phone" />
            </div>

            <div className="contact-item mod-fullwidth">
              <label htmlFor="body">What's the ad for?</label>
              <textarea id="body" name="body" required></textarea>
            </div>

            <div className="contact-submit">
              <button type="submit" disabled={this.state.isSending || this.state.justSent ? true : false} className="button button-regular">
                {this.determineButtonContents()}
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default Contact;