import React from 'react';

import appStoreIcon from '../images/appStoreIcon.svg';

export default function AppStoreButton(props) {
  return (
    <button disabled className={`app-store-soon ${props.classes ? props.classes : ''}`}>
      <div><span>Coming Soon</span></div>
      <img src={appStoreIcon} alt="Apple App Store" />
    </button>
  )
}