import React from 'react';

import Nav from './components/Nav';
import Footer from './components/Footer';

import Landing from './components/Landing';
import Advertise from './components/Advertise';
import AdDetails from './components/AdDetails';
import Privacy from './components/Privacy';
import TermsAndConditions from './components/TermsAndConditions';
import Contact from './components/Contact';

import './styles/main.scss';
import 'what-input';
import * as Firebase from 'firebase/app';
import 'firebase/storage';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

const firebaseConfig = {
  storageBucket: 'gs://humbly-app-7410e.appspot.com'
};
Firebase.initializeApp(firebaseConfig);
const storage = Firebase.storage();

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isMenuOpen: false,
      isButtonStuck: false,
      navBgClass: '',
      a11yNavMessage: null
    }
    this.menuHandler = this.menuHandler.bind(this);
    this.setNavBgClass = this.setNavBgClass.bind(this);
    this.a11yNavHandler = this.a11yNavHandler.bind(this);
    this.closeTimer = null;
  }

  a11yNavHandler(message) {
    this.setState({
      a11yNavMessage: message
    })
  }

  A11yNavNotifier = () => {
    return (
      <div className="visually-hidden" role="status" aria-live="polite" aria-atomic="true">
        {this.state.a11yNavMessage ? <span>{this.state.a11yNavMessage}</span> : ''}
      </div>
    )
  }

  menuHandler() {
    const body = document.body;
    const outer = document.querySelector('.outer');
    const currentOuterStyle = getComputedStyle(outer);
    let timing = currentOuterStyle['transition-duration'];
    timing = parseInt(timing.replace('.', '').replace('s', '')) * 100;
    clearTimeout(this.closeTimer);

    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen,
      isAnimating: !prevState.isAnimating
    }), () => {
      if (!this.state.isMenuOpen) {
        body.style.overflow = 'auto';
        this.closeTimer = setTimeout(() => {
          this.setState({ isButtonStuck: false })
        }, timing);
      } else {
        body.style.overflow = 'hidden';
        this.setState({ isButtonStuck: true })
      }
    })
  }

  setNavBgClass(bgClass) {
    this.setState({
      navBgClass: bgClass
    })
  }

  render() {
    return (
      <Router>
        <this.A11yNavNotifier />
        <Switch>
          <Route exact path="/">
            <div className={`outer ${this.state.isMenuOpen ? 'mod-menu-open-content' : ''}`}>
              <Nav
                menuHandler={this.menuHandler}
                isMenuOpen={this.state.isMenuOpen}
                isButtonStuck={this.state.isButtonStuck}
                navBgClass={this.state.navBgClass}
              />
              <Landing
                storage={storage}
                setNavBgClass={this.setNavBgClass}
                a11yNavHandler={this.a11yNavHandler}
              />
              <Footer terseFooter={true} />
            </div>
          </Route>
          <Route exact path="/advertise">
            <div className={`outer ${this.state.isMenuOpen ? 'mod-menu-open-content' : ''}`}>
              <Nav
                menuHandler={this.menuHandler}
                isMenuOpen={this.state.isMenuOpen}
                isButtonStuck={this.state.isButtonStuck}
                navBgClass={this.state.navBgClass}
              />
              <Advertise
                storage={storage}
                setNavBgClass={this.setNavBgClass}
                a11yNavHandler={this.a11yNavHandler}
              />
              <Footer terseFooter={false} />
            </div>
          </Route>
          <Route exact path="/contact">
            <div className={`outer ${this.state.isMenuOpen ? 'mod-menu-open-content' : ''}`}>
              <Nav
                menuHandler={this.menuHandler}
                isMenuOpen={this.state.isMenuOpen}
                isButtonStuck={this.state.isButtonStuck}
                navBgClass={this.state.navBgClass}
              />
              <Contact
                setNavBgClass={this.setNavBgClass}
                a11yNavHandler={this.a11yNavHandler}
              />
              <Footer terseFooter={false} />
            </div>
          </Route>
          <Route exact path="/ad-details">
            <div className={`outer ${this.state.isMenuOpen ? 'mod-menu-open-content' : ''}`}>
              <Nav
                menuHandler={this.menuHandler}
                isMenuOpen={this.state.isMenuOpen}
                isButtonStuck={this.state.isButtonStuck}
                navBgClass={this.state.navBgClass}
              />
              <AdDetails
                setNavBgClass={this.setNavBgClass}
                a11yNavHandler={this.a11yNavHandler}
              />
              <Footer
                terseFooter={false}
                colorfulSection={true}
              />
            </div>
          </Route>
          <Route path="/privacy">
            <div className={`outer ${this.state.isMenuOpen ? 'mod-menu-open-content' : ''}`}>
              <Nav menuHandler={this.menuHandler} />
              <Privacy
                storage={storage}
                a11yNavHandler={this.a11yNavHandler}
              />
              <Footer terseFooter={true} />
            </div>
          </Route>
          <Route path="/terms">
            <div className={`outer ${this.state.isMenuOpen ? 'mod-menu-open-content' : ''}`}>
              <Nav menuHandler={this.menuHandler} />
              <TermsAndConditions
                storage={storage}
                a11yNavHandler={this.a11yNavHandler}
              />
            </div>
            <Footer terseFooter={true} />
          </Route>
        </Switch>
      </Router>
    )
  }
}

export default App;
