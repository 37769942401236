import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from "react-router-dom";

import Wedge from './Wedge';
import Device from './Device';
import CostBreakDown from './CostBreakdown';

import '../styles/advertise.scss';

class Advertise extends React.Component {
  componentDidMount() {
    document.title = 'Humbly | Advertise with us';
    document.body.setAttribute('data-section', 'advertise');
    this.props.setNavBgClass('colorful-section');
    this.props.a11yNavHandler('Navigated to Advertise');
  }

  render() {
    return (
      <div className="page-wrapper">
        <section className="colorful-section advertise-section-1-offset">
          <div className="inner grid grid-halves advertise-section-1 advertise-section-1-correction">
            <div className="advertise-section-1-content">
              <h1>
                The ad network for podcasts.
              </h1>
              <p>
                Advertising with the Humbly ad network means people hear quality ads that are relevant to them. It also means that the money spent will partially go to supporting some awesome nonprofits and charities guided by our awesome users.
              </p>
              <div className="advertise-cta cta">
                <Link to="/contact">
                  <button className="button button-regular">
                    Get in touch
                  </button>
                </Link>
                <div className="advertise-cta-secondary">
                  <NavLink className="mod-witharrow" to="/ad-details">
                    Learn more
                    <span className="little-arrow"></span>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="device-wrapper">
              <Device source="advertise" />
            </div>
          </div>
        </section>
        <Wedge position="bottom" bgClass="colorful-section" />

        <section>
          <div className="advertise-section-2">
            <div className="inner grid grid-halves">
              <div className="advertise-list-feature">
                <h2>Easy</h2>
              </div>
              <div className="advertise-list-explanation">
                <p>
                  Getting quality, related audio ads to podcast listeners has never been easier. We can even produce your ad for you in our very own Humbly Studios - at no charge. Once you're happy with it, we'll set it free on the Humbly network.
                </p>
              </div>

              <div className="advertise-list-feature">
                <h2>Affordable</h2>
              </div>
              <div className="advertise-list-explanation">
                <p>
                  Humbly aims to compete with other forms of online audio advertising on cost. <Link to="/ad-details" className="mod-witharrow">Learn more<span className="little-arrow"></span></Link>
                </p>
                <CostBreakDown />
              </div>

              <div className="advertise-list-feature">
                <h2>Socially conscious</h2>
              </div>
              <div className="advertise-list-explanation">
                <p>
                  Advertising with Humbly is different because part of the money you spend on ads will go to charities and nonprofits that are selected by our awesome users.
                </p>
              </div>

              <div className="advertise-list-feature">
                <h2>Valuable</h2>
              </div>
              <div className="advertise-list-explanation">
                <p>
                  Ads in Humbly are valuable because the listener experiences them when they are looking at their device. This results in more brand visibility and the action button being tapped.
                </p>
                <p>
                  <br />Listeners can also replay your ad (at no cost to you) from their activity feed. In essence, every ad also doubles as a display ad - similar to Instagram or Twitter. This further drives your brand.
                </p>
              </div>

              <div className="advertise-list-feature">
                <h2>Local</h2>
              </div>
              <div className="advertise-list-explanation">
                <p>
                  Advertising to a specific area is an effective way of getting a great return on your ad spend. Find listeners on a state, city, or neighborhood level. Listeners can opt-out from getting ads that are hyper-local.
                </p>
              </div>

              <div className="advertise-list-feature">
                <h2>Actionable</h2>
              </div>
              <div className="advertise-list-explanation">
                <p>
                  Every Humbly Ad includes a call to action button. This button can be your brand color and can contain any short text. Tapping it takes the listener to whatever URL your provide. Simple, elegant, and effective.
                </p>
              </div>

              <div className="advertise-list-feature">
                <h2>Measurable</h2>
              </div>
              <div className="advertise-list-explanation">
                <p>
                  Future feature: You will know how well your ads have been working by seeing it first hand. Your dashboard It will show every detail down to the last cent, and which listener heard what ad when. Identifyable user data will not be shown.
                </p>
              </div>

              <div className="advertise-list-feature">
                <h2>Humane</h2>
              </div>
              <div className="advertise-list-explanation">
                <p>
                  Listening to ads does not need to be an inconvenience. Annoying, obnoxious, loud, irrelevant ads simply kill the mood. Humbly ads are designed to associate your brand with a nice ad experience and company that is giving back to the world.
                </p>
              </div>

              <div className="advertise-list-feature">
                <h2>High quality</h2>
              </div>
              <div className="advertise-list-explanation">
                <p>
                  Humbly has a reasonable standard for the experience of ads that are shown to users. To us, it's all about quality over quantity. We'd just rather show better ads. The nicer the ad listening experience, the more effective the ads become.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="advertise-section-3">
            <div className="inner">
              <div className="bottom-cta">
                <p>
                  Ready to advertise or have questions?
                </p>
                <Link to="/contact">
                  <button className="button button-regular">
                    Get in touch
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
};

Advertise.propTypes = {
  setNavBgClass: PropTypes.func
}

export default Advertise;